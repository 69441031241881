<template>
  <div class="container-scoped-p-bar">
    <div :style="fillerStyles">
      <!-- <span class="label-scoped-p-bar">{{ `${completed}%` }}</span> -->
    </div>
    <div class="percentage-scoped-p-bar" :style="fillerStyles2">
      {{ `${completed}%` }} {{ $t('completed') }}
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
export default {
  name: "ProgressBar",
  props: {
    completed: Number,
  },
  setup(props) {
    const fillerStyles = computed(() => {
      return {
        height: "100%",
        width: `${props.completed}%`,
        backgroundColor: '#019c09',
        transition: "width 1s ease-in-out",
        borderRadius: "inherit",
        textAlign: "right",
      };
    });
    const fillerStyles2 = computed(() => {
      return {
        width: `${props.completed}%`,
        transition: "width 1s ease-in-out",        
      };
    });

    return { fillerStyles };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.container-scoped-p-bar {
  height: 20px;
  width: 50%;
  background-color: #f7f7f7;
  border-radius: 50px;
  margin: 20px auto -50px;
}

.label-scoped-p-bar {
  padding: 0px;
  color: white;
  font-weight: bold;
}

.percentage-scoped-p-bar{
  color: #019c09;
  font-weight: bold;
  text-align: end;
  font-size: 20px;
}
</style>
