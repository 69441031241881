import moment from 'moment';

class date {
    static FORMAT_DATE = 'MM/DD/YYYY';
    static DATE_INPUT_FORMAT = 'YYYY-MM-DD';
    static FORMAT_DATE_TIME = 'MM/DD/YYYY hh:mm:ss A';
    static FORMAT_DATE_TIME_ENG = 'MMMM Do YYYY, hh:mm A';
    static FORMAT_DATE_TIME_APPOINTMENT = 'YYYY/MM/DD, hh:mm A';
    
    static calculateDateOfBirth(date) {
        try {
            const years = moment().diff(date, 'years', true);
            if ((years % 1) != 0) {
                const decimal = (years - Math.floor(years)) * 100;
                const months = (decimal / 100) * 12;
    
                return [Math.floor(years), Math.floor(months)];
            }
            return [Math.floor(years)];
        } catch {
            return [];
        }
    }

    // Formats the date as Mon DD, YYYY
    static formatDate(date) {
        try {
            return new Date(date.replace(/-/g, '\/')).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric' 
            });
        } catch {
            return '';
        }
    }

    static formatTimeStampToAppointmentFormat(timestamp) {
        if (timestamp) {
            const date = moment.unix(timestamp);

            const formattedDate = date.format('MM/DD/YYYY hh:mm A');

            return formattedDate;
        }
        return '';
    }

    // Change this date format YYYY-MM-DD to MM/DD/YYYY
    static formatDateToDefaultFormat(date) {
        return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
    }

    // Format Unix Timestamp to MM/DD/YYYY
    static formatUnixToDate (timestamp){
        return moment.unix(timestamp/1000).format('MM/DD/YYYY');
    }

    static formatToday() {
        const today = new Date();
        const year = today.getFullYear();
        const month = ('0' + (today.getMonth() + 1)).slice(-2);
        const day = ('0' + today.getDate()).slice(-2); 

        return year + '-' + month + '-' + day;
    }
}

export default date;