export default {
    //providerTypes
    providerType :{
        psychometrist: 'psychometrist',
        psychologist: 'psychologist',
        BCBA: 'bcba'
    },
    navigator: {
        connectivityError: "You are offline, please check your internet connectivity and try again.",
    },
    evaluationStages: {
        READY_TO_SCHEDULE: 'Ready to Schedule',
        CONSENTS_INCOMPLETE: 'Consents Incomplete',
        INCOMPLETE_INTAKE: 'Incomplete Intake',
        PAYMENT_METHOD_STOP: 'Payment Method Stop',
        MEDICAL_AUTHORITY_STOP: 'Medical Authority Stop',
        PRE_AUTH_STOP: 'Pre-Auth Stop',
        DENIED: 'Denied'
    },
}